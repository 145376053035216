import React, { useCallback } from 'react';
import { useHpProps } from 'src/hpProps';
import { RmcMfeLoader } from '@jarvis/react-mfe-component';

export const SubscriptionManager = (props) => {
  const { stack, localization } = useHpProps();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <RmcMfeLoader
          disableContainerPadding
          {...props}
          {...{
            localization,
            component,
            type,
            stack,
          }}
        />
      );
    },
    [stack, props],
  );

  return (
    <SubMfe
      type="subscriptionManager"
      component="@jarvis/react-subscription-manager"
      data-testid={'subscription-manager-mfe'}
    />
  );
};

SubscriptionManager.defaultProps = {
  stack: null,
};
