import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { AppsCard } from './AppsCard';
import { useHpProps } from 'src/hpProps';
import { getProps, t } from 'src/utils/commonUtils';
import { portfolioSvcApi } from 'src/api/portfolioSvc';
import { contentBrokerAPI } from 'src/api/contentBroker';
import { HpLoadingSection } from 'src/components/Common';

export const AppsCardContainer = () => {
  const { getPortfolio } = portfolioSvcApi();
  const { getAppInfo } = contentBrokerAPI();
  const { useToast } = useHpProps();

  const [appsData, setAppsData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    apiGetPortfolio().then((res) => {
      apiGetAppInfo(res)
        .then()
        .finally(() => {
          setIsFetching(false);
        });
    });
  }, []);

  useEffect(() => {
    if (error) {
      renderError();
      setIsFetching(false);
    }
  }, [error]);

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('unable_to_load_data'),
    });
  };

  const apiGetPortfolio = async () => {
    console.log('[solutions] api - (1) get portfolio list');

    const { response, error: portfolioError } = await getPortfolio();

    if (portfolioError != undefined) {
      setError(true);
      return;
    }

    return response.solutions.map((app) => {
      return {
        uuid: app.solutionUuid,
        name: app.solutionName,
        installCountThis: app.installCountThis,
        installCountSub: app.installCountSub,
      };
    });
  };

  const apiGetAppInfo = async (currentApps) => {
    console.log('[solutions] api - (2) get contents broker app list');

    const appFilter = {
      propertyName: 'uuid',
      propertyValue: currentApps?.map((app) => app.uuid),
    };

    const { response, error: appError } = await getAppInfo(appFilter);

    if (appError != undefined) {
      console.log('asd/appError');
      setError(true);
      setAppsData([]);
      return;
    }

    const mappedData = getNewAppData(currentApps, response);
    setAppsData(mappedData);
  };

  const getNewAppData = (currentApps, totalApps) => {
    const newAppsData = [...currentApps];

    for (let i = 0; i < newAppsData.length; i++) {
      //search requested app uuid from all app information
      const searchedApp = totalApps.find((app) => {
        return app.appUuid == newAppsData[i].uuid;
      });

      if (searchedApp != undefined) {
        //search requested locale's description
        const localProps = getProps();
        let description = searchedApp.localeInfoList.find((locale) => {
          return locale.locale == `${localProps.language}_${localProps.country.toUpperCase()}`;
        })?.description;

        if (description == undefined) {
          //If there is no requested locale's description, search english description
          description = searchedApp.localeInfoList.find((locale) => {
            return locale.locale == 'en_US';
          }).description;
        }

        newAppsData[i] = { ...newAppsData[i], icon: searchedApp.icon, description: description };
      } else {
        newAppsData[i] = { ...newAppsData[i], icon: 'noIcon', description: '--' };
      }
    }
    return newAppsData;
  };

  const renderAppsCard = useMemo(() => {
    return <AppsCard appsData={appsData} />;
  }, [appsData]);

  return (
    <>
      {isFetching ? (
        <HpLoadingSection />
      ) : (
        <Wrapper data-testid="app-list-card-container">{renderAppsCard}</Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: contents;
  .appCard {
    min-width: 524px;
  }
`;
