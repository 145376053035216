import { createContext } from 'react';

const ecpDeviceV55 = null;
const stack = null;
const shell = null;
const localization = null;
const events = null;
const useToast = null;
const authProvider = null;
const navigation = null;
const getBreadcrumb = null;
const setBreadcrumb = null;
const removeBreadcrumb = null;
const tenantId = null;
const featureFlags = null;

const hpProps = createContext({
  ecpDeviceV55,
  stack,
  localization,
  shell,
  events,
  useToast,
  authProvider,
  navigation,
  getBreadcrumb,
  setBreadcrumb,
  removeBreadcrumb,
  tenantId,
  featureFlags,
});

export default hpProps;
