import moment from 'moment';
import { dateFormat } from 'src/utils/constants';

export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};
export const refinedDate = (data: any, format: string = dateFormat.default) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(format);
    else return '--';
  }
};

export const refinedArray = (data) => {
  if (data === undefined || data === null) {
    return [];
  } else {
    return data;
  }
};
