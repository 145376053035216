import { AxiosResponse } from 'axios';
import { DeviceApiClient } from '@jarvis/web-stratus-client';

export enum PortfolioClientApi {
  SOLUTION_PORTFOLIO = '/portfolio',
  SOLUTION_CATALOG = '/catalog',
}

export enum HttpHeaders {
  CONTENT_TYPE = 'Content-Type',
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
}

export class PortfolioSvcClient extends DeviceApiClient {
  jarvisWebHttpInstance;
  apiName = 'tropos-rnd.com/portfoliomgt-api';
  apiVersion = '1';

  /**
   * Retrieve portfolio list from app catalog
   * @param {string} tenantId A tenant Id
   * @returns Axios response object
   */

  async getPortfolio(tenantId): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: PortfolioClientApi.SOLUTION_PORTFOLIO,
      params: {
        tenantId,
      },
      validationSchema: PortfolioSvcClient.portfolioSchema(),
    });
  }

  /**
   * Returns the expected schema for create portfolio list response object.
   *
   * @returns schema in object format
   */
  static portfolioSchema(): any {
    return {
      $schema: 'http://json-schema.org/schema#',
      type: 'object',
      additionalProperties: true,
      properties: {
        solutions: {
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true,
            properties: {
              tenantId: {
                type: ['string', 'null'],
              },
              tenantName: {
                type: ['string', 'null'],
              },
              solutionName: {
                type: ['string', 'null'],
              },
              solutionUuid: {
                type: ['string', 'null'],
              },
              parentTenantId: {
                type: ['string', 'null'],
              },
              parentTenantName: {
                type: ['string', 'null'],
              },
              installCountThis: {
                type: ['number', 'null'],
              },
              installCountSub: {
                type: ['number', 'null'],
              },
              serviceProviderData: {
                type: ['object', 'null'],
                additionalProperties: true,
                properties: {
                  subscription: {
                    type: ['boolean', 'null'],
                  },
                  distributable: {
                    type: ['boolean', 'null'],
                  },
                  subscriptionStatus: {
                    type: ['string', 'null'],
                  },
                  unsubscribedAt: {
                    type: ['string', 'null'],
                  },
                  gracePeriodDays: {
                    type: ['number', 'null'],
                  },
                },
              },
              customerData: {
                type: ['object', 'null'],
                additionalProperties: true,
                properties: {
                  gracePeriodUntil: {
                    type: ['string', 'null'],
                  },
                },
              },
              createdAt: {
                type: ['string', 'null'],
              },
              updatedAt: {
                type: ['string', 'null'],
              },
            },
          },
        },
      },
    };
  }
}
