import { collectionAPI } from 'src/api/collection';

export const useCollections = (setSolutions) => {
  const { getCollections } = collectionAPI();

  const apiGetSolutions = async () => {
    const { response: allGroups, error: allGroupsError } = await getCollections(0, 1, 'All');

    if (allGroupsError !== undefined) {
      setSolutions([]);
      return;
    }

    setSolutions(
      allGroups.contents.map((content) => ({
        grant: 'ws-hp.com/smcloud',
        count: content.devices,
      })),
    );
  };

  return { apiGetSolutions };
};
