import { Grants } from '@jarvis/web-organization/dist/src';
import { useHpProps } from 'src/hpProps';

export const useSharedMFE = (setSolutions) => {
  const { stack, authProvider, tenantId } = useHpProps();
  const grants = new Grants();

  const apiGetSolutions = async () => {
    const solutionsList = await grants.getGrants(stack, authProvider, tenantId);
    const filteredSolutionList = solutionsList.contents.filter((solution: any) => {
      if (solution.level != undefined && solution.level == 'DEVICE') {
        return true;
      }
      return false;
    });
    setSolutions(filteredSolutionList);
  };

  return { apiGetSolutions };
};
