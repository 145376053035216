export const store = {
  solutions: {
    START_ROOT_COMPONENT: 'solutions/startRootComponent',
    START_SOLUTION_CARD_API: 'solutions/startSolutionCardAPI',
    APPS_CONTENT: 'solutions/appsContent',
    SOLUTIONS_CONTENT: 'solutions/solutionsContent',
  },
};

export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const service = {
  collection: 'collection',
  accountMgt: 'accountMgt',
  commercePortal: 'commercePortal',
  contentBroker: 'contentBroker',
  deviceCache: 'deviceCache',
  deviceShadow: 'deviceShadow',
  fleetMgt: 'fleetMgt',
  programMgt: 'programMgt',
  solutionRegistry: 'solutionRegistry',
  subscriptions: 'subscriptions',
  telemetry: 'telemetry',
  portfolio: 'portfolio',
};
