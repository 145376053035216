import AssetsProviderFactory from '../assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { storeStartRootComponent, storeStartSolutionCardAPI } from 'src/store/solutions/action';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = (props) => {
  localProps.authProvider = props.authProvider;
  localProps.language = props.localization.language;
  localProps.stack = props.stack;
  localProps.country = props.localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-solutions.${subkey}`, args);
};

export const refreshSolutionCardAPI = (dispatch) => {
  dispatch(storeStartSolutionCardAPI(false));

  setTimeout(() => {
    dispatch(storeStartSolutionCardAPI(true));
  }, 100);
};

export const refined = (data: any) => {
  if (data === undefined) {
    return t('unable_to_load_data');
  } else if (data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const refreshRootComponent = (dispatch) => {
  dispatch(storeStartRootComponent(false));

  setTimeout(() => {
    dispatch(storeStartRootComponent(true));
  }, 100);
};
