import React from 'react';
import { Card, CustomImage, IconChevronRight, IconSmartApp, Tooltip } from '@veneer/core';
import styled from 'styled-components';
import { t } from 'src/utils/commonUtils';
import { Link } from 'react-router-dom';

export const AppsCard = (props) => {
  const renderAppList = (appData) => {
    return (
      <AppListCardSection data-testid={`app-list-card-${appData.uuid}`}>
        <IconSection>
          {appData.icon == 'noIcon' ? (
            <StyledIconSmartApp />
          ) : (
            <CustomImage size={64} quietArea={0} src={appData.icon} />
          )}
        </IconSection>

        <ContentsSection>
          <AppName>{appData.name}</AppName>
          <Tooltip content={<div>{appData.description}</div>} arrow>
            <Description>{appData.description}</Description>
          </Tooltip>
          <Installed data-testid={`installed-device-count-${appData.uuid}`}>
            {`${appData.installCountThis + appData.installCountSub} ${t('devices_installed')}`}
          </Installed>
        </ContentsSection>
        <LinkSection data-testid={`move-to-app-detail-${appData.uuid}`}>
          <Link to={`/app/${appData.uuid}`}>
            <IconChevronRight />
          </Link>
        </LinkSection>
      </AppListCardSection>
    );
  };

  return (
    <>
      {props.appsData.map((appData) => {
        return (
          <div key={`${appData.uuid}`}>
            <StyledCard
              id={appData.uuid}
              className="appCard"
              hoverable
              content={renderAppList(appData)}
            />
          </div>
        );
      })}
    </>
  );
};

const StyledCard = styled(Card)`
  box-shadow: unset;
  margin-right: 24px;
  //width: 50%;
`;

const AppListCardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;
`;

const IconSection = styled.div`
  height: 100%;
  padding-top: 30px;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

const AppName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 25px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Installed = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

const LinkSection = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

const StyledIconSmartApp = styled(IconSmartApp)`
  width: 64px !important;
  height: 64px !important;
`;
