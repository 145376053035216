import styled from 'styled-components';
import React, { useCallback } from 'react';
import { t } from 'src/utils/commonUtils';
import { useHpProps } from 'src/hpProps';
import { Card, CustomImage, IconChevronRight } from '@veneer/core';

export const SecureFleetManagerCard = (props: { devicesCount: number }) => {
  const { navigation } = useHpProps();

  const moveToEndpointSecurity = useCallback(() => {
    navigation.push('/solutions/security');
  }, []);

  // hard coded solution card
  const endpointSecurity = () => {
    return (
      <HardCodedSolutionCardSection data-testid="secure-fleet-manager-card">
        <IconSection>
          <CustomImage
            size={64}
            quietArea={0}
            src="https://docs.hpcommandcenter.com/images/endpointSecurity.png"
          />
        </IconSection>

        <ContentsSection>
          <SolutionName>HP Secure Fleet Manager</SolutionName>
          <Description>{t('assess_fleet_of_devices')}</Description>
          <Entitled data-testid="entitled-devices-count">
            {`${props.devicesCount} ${t('devices_entitled')}`}
          </Entitled>
        </ContentsSection>

        <LinkSection data-testid="move-to-secure-fleet-manager" onClick={moveToEndpointSecurity}>
          <IconChevronRight />
        </LinkSection>
      </HardCodedSolutionCardSection>
    );
  };

  return (
    <div data-testid="secure-fleet-manager-card">
      <StyledCard
        id={'endpointSecurityCard'}
        className="solutionCard"
        hoverable
        content={endpointSecurity()}
      />
    </div>
  );
};

const StyledCard = styled(Card)`
  box-shadow: unset;
  margin-right: 24px;
  //width: 50%;
`;

const HardCodedSolutionCardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;
`;

const IconSection = styled.div`
  position: absolute;
  top: 15px;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
`;

const SolutionName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 25px;
  line-height: 20px;
`;

const Entitled = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

const LinkSection = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
