import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHpProps } from 'src/hpProps';
import { RmcMfeLoader } from '@jarvis/react-mfe-component';

export const SecureFleetManager = (props) => {
  const { stack, localization } = useHpProps();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <RmcMfeLoader
          disableContainerPadding
          {...props}
          {...{
            localization,
            component,
            type,
            stack,
          }}
        />
      );
    },
    [stack, props],
  );

  return (
    <SubMfe
      type="endpointSecurity"
      component="@jarvis/react-ecp-endpointsecurity-homepage"
      data-testid={'secure-fleet-manager-mfe'}
    />
  );
};

SecureFleetManager.defaultProps = {
  stack: null,
};

SecureFleetManager.propTypes = {
  stack: PropTypes.number,
};
