import React, { useMemo } from 'react';
import HpProps from './hpProps';
import { Stack } from '@jarvis/web-stratus-client';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const HpPropsProvider = (props) => {
  const flags = useFlags();
  const value = useMemo(
    () => ({
      ecpDeviceV55: props.ecpDeviceV55,
      stack: props.stack ?? Stack.pie,
      shell: props.shell,
      localization: props.localization,
      events: props.events,
      useToast: props.useToast(),
      authProvider: props.authProvider,
      navigation: props.navigation,
      getBreadcrumb: props.getBreadcrumb,
      setBreadcrumb: props.setBreadcrumb,
      removeBreadcrumb: props.removeBreadcrumb,
      tenantId: props.tenantId ?? '',
      featureFlags: flags,
    }),
    [],
  );
  return <HpProps.Provider value={value}>{props.children}</HpProps.Provider>;
};
