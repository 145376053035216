import { refined } from 'src/utils/commonUtils';

export const refinedGetCollections = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents.map((obj) => ({
            devices: refined(obj.devices),
          }))
        : [{ devices: 0 }],
  };
};
