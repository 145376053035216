import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import { Provider } from 'react-redux';
import { ShellProps } from './types/shell';
import { RootComponent } from '../src';
import { configureStore } from '@reduxjs/toolkit';
import { HpPropsProvider } from 'src/hpProps';
import { ToastProvider, useToast, ThemeProvider } from '@veneer/core';
import { RmcUtils } from '@jarvis/react-mfe-component';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps;
  const namespace = '@jarvis/react-ecp-solutions';
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();
  const [LDProvider, setLDProvider] = useState(null);
  const getTenantId = () => {
    const tenantIdsMap = props.tenantHandler.getTenantIdsMap();
    if (tenantIdsMap != undefined) {
      if (tenantIdsMap.stratusCustomer != undefined) {
        return tenantIdsMap.stratusOrganization + '__' + tenantIdsMap.stratusCustomer;
      } else {
        return tenantIdsMap.stratusOrganization;
      }
    }
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await RmcUtils.launchDarkly.getLDProvider();

      setLDProvider(() => Provider);
    };

    setProvider();
  }, []);

  return LDProvider ? (
    <section id={namespace}>
      <ThemeProvider {...themeProviderProps}>
        <ToastProvider>
          <LDProvider>
            <HpPropsProvider
              id={packageInfo.name}
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              stack={props.stack}
              shell={props.shell}
              localization={props.localization}
              useToast={useToast}
              events={props.events}
              authProvider={v1.authProvider}
              navigation={props.navigation}
              getBreadcrumb={props.breadcrumbs.getBreadcrumbs}
              setBreadcrumb={props.breadcrumbs.add}
              removeBreadcrumb={props.breadcrumbs.remove}
              tenantId={getTenantId()}
              featureFlags={null}
            >
              <Provider store={store}>
                <RootComponent {...props} />
              </Provider>
            </HpPropsProvider>
          </LDProvider>
        </ToastProvider>
      </ThemeProvider>
    </section>
  ) : (
    <></>
  );
}
