import styled from 'styled-components';
import React, { useCallback } from 'react';
import { t } from 'src/utils/commonUtils';
import { useHpProps } from 'src/hpProps';
import { Card, CustomImage, IconChevronRight } from '@veneer/core';

export const SubscriptionManagerCard = () => {
  const { navigation } = useHpProps();

  const moveToSubscriptionManager = useCallback(() => {
    navigation.push('/solutions/subscription-manager');
  }, []);

  const subscriptionManager = () => {
    return (
      <HardCodedSolutionCardSection>
        <IconSection>
          <CustomImage
            size={64}
            quietArea={0}
            src="https://docs.hpcommandcenter.com/images/endpointSecurity.png"
          />
        </IconSection>

        <ContentsSection>
          <SolutionName>{t('subscription_manager')}</SolutionName>
          <Description>
            {t('Access your subscriptions, prepayments, reports and update payment methods.')}
          </Description>
          <BlankSpace></BlankSpace>
        </ContentsSection>
        <LinkSection data-testid="move-to-subscription-manager" onClick={moveToSubscriptionManager}>
          <IconChevronRight />
        </LinkSection>
      </HardCodedSolutionCardSection>
    );
  };

  return (
    <div data-testid="subscription-manager-card">
      <StyledCard
        id={'subscriptionManger'}
        className="solutionCard"
        hoverable
        content={subscriptionManager()}
      />
    </div>
  );
};

const StyledCard = styled(Card)`
  box-shadow: unset;
  margin-right: 24px;
  width: 50%;
`;

const HardCodedSolutionCardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;
`;

const IconSection = styled.div`
  position: absolute;
  top: 15px;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
`;

const SolutionName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 25px;
  line-height: 20px;
`;

const BlankSpace = styled.div`
  height: 24px;
  margin-top: 10px;
`;

const LinkSection = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
