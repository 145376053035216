import { DeviceApiClient } from '@jarvis/web-stratus-client';
import { JarvisWebHttpClient } from '@jarvis/web-http';

export enum ContentBrokerApi {
  RELAY = '/relay',
}

export enum HttpHeaders {
  CONTENT_TYPE = 'Content-Type',
  X_METHOD_OVERRIDE = 'X-HTTP-Method-Override',
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
  GET = 'GET',
}

export class ContentBrokerClient extends DeviceApiClient {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  jarvisWebHttpInstance: JarvisWebHttpClient;
  apiName = 'tropos-rnd.com/content-broker-api';
  apiVersion = '1';

  /**
   * Relay the response from Arrakis Content Mgt Service
   * @returns Axios response object
   */

  async getManagedApp(filter?, startIdx?, endIdx?): Promise<any> {
    const criterionList = [
      {
        operation: 'eq',
        propertyName: 'latest',
        propertyValue: true,
      },
      {
        operation: 'eq',
        propertyName: 'state',
        propertyValue: 'Published',
      },
    ];

    if (filter) {
      criterionList.push({
        operation: 'in',
        propertyName: filter.propertyName,
        propertyValue: filter.propertyValue,
        // criterion: null,
      });
    }

    return this.jarvisWebHttpInstance.post({
      url: ContentBrokerApi.RELAY,
      data: {
        method: 'POST',
        url: 'managedappinfos/managedapp',
        header: {
          [HttpHeaders.X_METHOD_OVERRIDE]: ContentType.GET,
          [HttpHeaders.CONTENT_TYPE]: ContentType.APPLICATION_JSON,
        },
        body: JSON.stringify({
          startIndex: startIdx ?? 0, //pagination
          maxResultSetSize: endIdx ?? -1, //pagination
          criterionList: criterionList,
        }),
      },
      validationSchema: ContentBrokerClient.managedAppSchema(),
    });
  }

  /**
   * Returns the expected schema for create managed app list response object.
   *
   * @returns schema in object format
   */
  static managedAppSchema() {
    return {
      $schema: 'http://json-schema.org/schema#',
      totalCount: 'number',
      resourceList: 'array',
      items: {
        type: 'object',
        additionalProperties: true,
      },
    };
  }
}
