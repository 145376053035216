import styled from 'styled-components';
import React from 'react';

import { Card, CustomImage } from '@veneer/core';

export const RoamForBusinessCard = () => {
  const roamForBusiness = () => {
    return (
      <HardCodedSolutionCardSection>
        <IconSection>
          <CustomImage
            size={64}
            quietArea={0}
            src="https://docs.hpcommandcenter.com/images/endpointSecurity.png"
          />
        </IconSection>
        <ContentsSection>
          {/* TODO: Translation */}
          <SolutionName>Roam for Business</SolutionName>
          <Description>
            Submit print jobs while on the go from any devices, and release any of your HP
            Roam-enabled office printers.
          </Description>
          {/*<Entitled>{devicesCountOfCurrentUser} Devices entitled</Entitled>*/}
        </ContentsSection>
      </HardCodedSolutionCardSection>
    );
  };

  return (
    <div>
      <StyledCard
        id={'roamForBusiness'}
        data-testid="roam-for-business-card"
        className="solutionCard"
        hoverable
        content={roamForBusiness()}
      />
    </div>
  );
};

const StyledCard = styled(Card)`
  box-shadow: unset;
  margin-right: 24px;
  width: 50%;
`;

const HardCodedSolutionCardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;
`;

const IconSection = styled.div`
  position: absolute;
  top: 15px;
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
`;

const SolutionName = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 25px;
  line-height: 20px;
`;
