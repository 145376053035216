import { refined, refinedArray } from 'src/utils/refinedMethods';

export const refinedGetAppInfo = (data) => {
  return data?.length > 0
    ? data.map((item) => ({
        appUuid: refined(item.appUuid),
        title: refined(item.title),
        icon: refined(item.icon),
        localeInfoList: refinedArray(item.localeInfoList).map((li) => ({
          locale: refined(li.locale),
          description: refined(li.description),
          screenshotList: refined(li.screenshotList),
        })),
        simplifiedAppID: refined(item.simplifiedAppID),
        supportLink: refined(item.supportLink),
        platformType: refined(item.platformType),
        platformVersion: refined(item.platformVersion),
        appSize: refined(item.appSize),
        isFree: refined(item.isFree),
        permissionList: refinedArray(item.permissionList),
      }))
    : [];
};
