import { refined, refinedDate } from 'src/utils/refinedMethods';

export const refinedGetPortfolio = (data) => {
  return {
    solutions:
      data.solutions?.length > 0
        ? data.solutions.map((item) => ({
            tenantId: refined(item.tenantId),
            tenantName: refined(item.tenantName),
            solutionName: refined(item.solutionName),
            solutionUuid: refined(item.solutionUuid),
            parentTenantId: refined(item.parentTenantId),
            parentTenantName: refined(item.parentTenantName),
            installCountThis: refined(item.installCountThis),
            installCountSub: refined(item.installCountSub),
            serviceProviderData: {
              subscription: refined(item.subscription),
              distributable: refined(item.distributable),
              subscriptionStatus: refined(item.subscriptionStatus),
              unsubscribedAt: refinedDate(item.unsubscribedAt),
              gracePeriodDays: refined(item.gracePeriodDays),
            },
            customerData: {
              gracePeriodUntil: refinedDate(item.gracePeriodUntil),
            },
            createdAt: refinedDate(item.createdAt),
            updatedAt: refinedDate(item.updatedAt),
          }))
        : [],
  };
};
