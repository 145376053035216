import styled from 'styled-components';
import React, { useMemo, useEffect, useState } from 'react';
import { t } from 'src/utils/commonUtils';
import { useHpProps } from 'src/hpProps';
import { HpLoadingSection } from 'src/components/Common';
import { Card, IconWarningAlt } from '@veneer/core';
import { SubscriptionManagerCard } from './SubscriptionManagerCard';

export const SubscriptionManagerCardContainer = (props: { error: boolean }) => {
  const { useToast } = useHpProps();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    apiForSubscriptionManager()
      .then()
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    if (props.error) {
      renderError();
    }
  }, [props.error]);

  const renderError = () => {
    useToast.addToast({ id: 'error', type: 'negative', text: t('unable_to_load_data') });
  };

  // To get the number of all devices.
  const apiForSubscriptionManager = async () => {
    console.log('[solutions] apiForSubscriptionManager');
  };

  const enableCondition = !isFetching;

  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      console.log('[solutions] render SubscriptionManagerCard');

      return <SubscriptionManagerCard />;
    } else {
      return <></>;
    }
  }, [enableCondition]);

  const errorComponent = () => {
    return (
      <ErrorWrapper>
        <IconBackground>
          <IconWarningAlt />
        </IconBackground>
        <ErrorMessage data-testid="solution-subscription-manager-errmsg">
          {t('something_unexpected_happened')}
        </ErrorMessage>
      </ErrorWrapper>
    );
  };

  return (
    <Wrapper data-testid="subscription-manager-card-container">
      {isFetching ? (
        <HpLoadingSection />
      ) : (
        <>
          {props.error ? (
            <StyledCard
              id={'errorComponent'}
              className="errorCard"
              hoverable
              content={errorComponent()}
            />
          ) : (
            <>{memoRenderComponent}</>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .solutionCard,
  .errorCard {
    min-width: 524px;
  }

  @media screen and (max-width: 840px) {
    display: flex;
    flex-direction: column;

    .solutionCard {
      margin-bottom: 15px;
    }
  }
`;

const StyledCard = styled(Card)`
  box-shadow: unset;
  margin-right: 24px;
  width: 50%;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 474px;
  height: 156px;
`;

const IconBackground = styled.div`
  background: rgba(33, 33, 33, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 8px;
`;
