import React, { useCallback, useEffect } from 'react';
import { useHpProps } from 'src/hpProps';
import { useDispatch } from 'react-redux';
import { RmcMfeLoader } from '@jarvis/react-mfe-component';
import { refreshSolutionCardAPI } from 'src/utils/commonUtils';

export const GlobalHeader = () => {
  const dispatch = useDispatch();
  const { events, localization } = useHpProps();

  const handleReloadPage = () => {
    refreshSolutionCardAPI(dispatch);
  };

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', handleReloadPage);
    return () => {
      events.removeEventListener('ecp-banner-reload-call', handleReloadPage);
    };
  }, [events, handleReloadPage]);

  const SubMfe = useCallback(({ component, type }) => {
    return (
      <RmcMfeLoader
        disableContainerPadding
        {...{
          localization,
          component,
          type,
        }}
      />
    );
  }, []);

  const renderGlobalHeader = () => {
    return (
      <div className="react-ecp-header-wrapper">
        <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
      </div>
    );
  };

  return renderGlobalHeader();
};
