import styled from 'styled-components';
import React, { useMemo } from 'react';
import { SecureFleetManagerCard } from './SecureFleetManagerCard';

export const SecureFleetManagerCardContainer = (props: { count: number }) => {
  const devicesCount = props.count;

  const enableCondition = devicesCount !== null;
  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      console.log('[solutions] render SecureFleetManagerCard');

      return (
        <SecureFleetManagerCard
          {...{
            devicesCount,
          }}
        />
      );
    } else {
      return <></>;
    }
  }, [enableCondition]);

  return (
    <Wrapper data-testid="secure-fleet-manager-card-container">
      <>{memoRenderComponent}</>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .solutionCard,
  .errorCard {
    min-width: 524px;
  }

  @media screen and (max-width: 840px) {
    display: flex;
    flex-direction: column;

    .solutionCard {
      margin-bottom: 15px;
    }
  }
`;
