import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useHpProps } from 'src/hpProps';
import { useStoreState } from 'src/store/useStoreState';
import { SecureFleetManagerCardContainer } from './SecureFleetManagerCardContainer';
import { SubscriptionManagerCardContainer } from './SubscriptionManagerCardContainer';
import { RoamForBusinessCardContainer } from './RoamForBusinessCardContainer';
import { SecureFleetManagerAdvancedCardContainer } from './SecureFleetManagerAdvancedCardContainer';
import { useSharedMFE } from './useSharedMFE';
import { useCollections } from './useCollections';

export const SolutionsMain = () => {
  const [solutions, setSolutions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const { ecpDeviceV55, featureFlags, getBreadcrumb, setBreadcrumb, removeBreadcrumb } =
    useHpProps();
  const { solutionsState } = useStoreState();
  const { startSolutionCardAPI } = solutionsState;

  const { apiGetSolutions: apiFromSharedMFE } = useSharedMFE(setSolutions);
  const { apiGetSolutions: apiFromCollections } = useCollections(setSolutions);

  useEffect(() => {
    setIsFetching(true);
    const newBreadcrumb = { text: 'Solutions', url: '/solutions', key: 'menu-solutions' };
    if (getBreadcrumb().length > 2) {
      removeBreadcrumb(getBreadcrumb().at(-1).key);
    }

    if (featureFlags.enableSharedMfeLib) {
      apiFromSharedMFE().finally(() => {
        setIsFetching(false);
      });
    } else {
      apiFromCollections().finally(() => {
        setIsFetching(false);
      });
    }

    const isExist = getBreadcrumb()
      .map((obj) => obj.text)
      .includes('Solutions');

    if (!isExist) setBreadcrumb(newBreadcrumb);
  }, []);

  const enableCondition = startSolutionCardAPI && !isFetching;

  return (
    <>
      <Wrapper data-testid="solutions-main">
        {enableCondition && (
          <Contents>
            {solutions.map((solution) => {
              switch (solution.grant) {
                case 'ws-hp.com/smcloud':
                  return <SecureFleetManagerCardContainer count={solution.count} />;
                default:
                  return <></>;
              }
            })}
            {ecpDeviceV55 && <SecureFleetManagerAdvancedCardContainer error={false} />}
            {ecpDeviceV55 && <RoamForBusinessCardContainer error={false} />}
            {ecpDeviceV55 && <SubscriptionManagerCardContainer error={false} />}
          </Contents>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;
`;
