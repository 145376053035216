import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.solutions.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeStartSolutionCardAPI = (_payload) => {
  return {
    type: store.solutions.START_SOLUTION_CARD_API,
    payload: _payload,
  };
};
