import styled from 'styled-components';
import React from 'react';
import { AppsCardContainer } from 'src/components/RootComponent/AppsMain/AppsCardContainer';

export const AppsMain = () => {
  return (
    <Wrapper data-testid="apps-main">
      <Contents>
        <AppsCardContainer />
      </Contents>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  height: auto;
`;

const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;
`;
