import { store } from 'src/utils/constants';

const initialState = {
  startRootComponent: false,
  startSolutionCardAPI: true,
};

const solutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.solutions.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.solutions.START_SOLUTION_CARD_API:
      return {
        ...state,
        startSolutionCardAPI: action.payload,
      };
    default:
      return state;
  }
};

export default solutionsReducer;
