import 'src/styles/global.scss';
import React from 'react';
import { setProps } from 'src/utils/commonUtils';
import { useDispatch } from 'react-redux';
import { useHpProps } from 'src/hpProps';
import { SolutionsMain } from './SolutionsMain';
import { useStoreState } from 'src/store/useStoreState';
import { SecureFleetManager } from './SubMfe/SecureFleetManager';
import { SubscriptionManager } from './SubMfe/SubscriptionManager';
import { RmcCustomHook, RmcUtils } from '@jarvis/react-mfe-component';
import { getSolutionsStoreActions } from 'src/store/solutions/init';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppsMain } from 'src/components/RootComponent/AppsMain';
import styled from 'styled-components';
import { GlobalHeader } from 'src/components/RootComponent/SolutionsMain/GlobalHeader';
import SubMfeAppOverview from 'src/components/RootComponent/AppsMain/AppsCardContainer/SubMfeAppOverview';

export const RootComponent = (props) => {
  const { solutionsState } = useStoreState();
  const { startRootComponent } = solutionsState;
  const { navigation, tenantId, featureFlags } = useHpProps();
  const baseurl = props.customRelativePath || '/solutions';
  const baseName = navigation ? navigation.createHref({ pathname: baseurl }) : baseurl;
  const dispatch = useDispatch();

  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  RmcCustomHook.useConstructor(() => {
    setProps(props);
    RmcUtils.commonMethods.setRmcProps(props);

    dispatchAll(getSolutionsStoreActions());
  });

  const didReduxUpdated = () => {
    return startRootComponent;
  };

  const renderRootComponent = () => {
    if (didReduxUpdated()) {
      return (
        <Router basename={baseName} data-testid="root-component">
          <Switch>
            <Route exact path="/(|solutions)">
              <Wrapper>
                <GlobalHeader />
                <SolutionsMain />
                {featureFlags.enableAppList && <AppsMain />}
              </Wrapper>
            </Route>
            <Route path="/security">
              <SecureFleetManager {...props} />
            </Route>
            <Route path="/subscription-manager">
              <SubscriptionManager {...props} />
            </Route>
            <Route path="/app/:appUuid">
              <SubMfeAppOverview {...props} />
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <></>;
    }
  };

  return renderRootComponent();
};

const Wrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  height: auto;
`;
